<template>
  <v-container fluid v-if="events">
    <v-row>
      <h1 class="mb-6">Events List</h1>
    </v-row>
    <v-row v-for="event in events" :key="event.id">
      <v-col cols="12">
        <v-row>
          {{ event.id }} — {{ event.name }}
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DebugEventsList",
  computed: {
    ...mapGetters({
      events: "events/events",
    }),
  },
  watch: {
    $route() {
      this.$store.dispatch("events/getEventList");
    },
  },
  mounted() {
    this.$store.dispatch("events/getEventList");
  },
};
</script>